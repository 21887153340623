import React from 'react'
//Material
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
//icons
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
//Utils
import { fDateUtc } from 'utils/functions';

//Files
import doc1 from 'commons/assets/files/CERTIFICADO_DE_REFRENDACIÓN_N°605 (1).pdf';
import doc2 from 'commons/assets/files/CERTIFICADO_DE_REFRENDACIÓN_N°605 (3).pdf';
import doc3 from 'commons/assets/files/CERTIFICADO_DE_REFRENDACIÓN_N°605.pdf';
import doc4 from 'commons/assets/files/CONTRATO_143-2023_MANTENCIÓN_Y_REPARACIÓN_REAULT_TRUCKS_DEFENSE.pdf';
import doc5 from 'commons/assets/files/Declaración_jurada_Salfa.pdf';
import doc6 from 'commons/assets/files/GARANTÍA_DE_FIEL_Y_OPORTUNO_CUMPLIMIENTO.pdf';
import doc7 from 'commons/assets/files/INFORME_DE_JUSTIFICACIÓN.pdf';
import doc8 from 'commons/assets/files/Orden de Compra 5240-320-SE23.pdf';
import doc9 from 'commons/assets/files/Presentación Valorización Salfa.pdf';
import doc10 from 'commons/assets/files/REQUERIMIENTOS_TECNICOS_SERVICIO_MANT._Y_REPAR._VEHÍCULOS_RENAULT (1).pdf';
import doc11 from 'commons/assets/files/RESOLUCIÓN_EXENTA_N°926_ACCEDE_A_TRATO_DIRECTO (1).pdf';
import doc12 from 'commons/assets/files/RESOLUCIÓN_N°01_APRUEBA_CONTRATO_N°143-2023..pdf';
import doc13 from 'commons/assets/files/Valorización Mano de obra PVP.pdf';
import doc14 from 'commons/assets/files/Valorización Mano de obra Sherpa.pdf';
import doc15 from 'commons/assets/files/Valorización repuestos Portal.pdf';
import doc16 from 'commons/assets/files/Valorización Repuestos Sherpa.pdf';

//Styles
import styles from './styles';
import Paths from 'utils/paths';
const useStyles = makeStyles(styles)

const TableDocuments = (props) => {
  const classes = useStyles();

  const columns = [
    {
      field: 'name',
      headerName: 'Nombre',
      flex: 4,
      renderCell: (params) => (
        <Typography component="div">
          <div className="flex-section">
            <InsertDriveFileIcon color='primary' className={classes.mr10} />
            <Typography variant="body2">
              {params.getValue("name")}
            </Typography>
          </div>
        </Typography>
      ),
    },
    {
      field: 'date',
      headerName: 'Fecha',
      flex: 1,
      renderCell: (params) => (
        <Typography component="div">
          <div className="flex-section">
            <p>{fDateUtc(params.getValue("date"))}</p>
          </div>
        </Typography>
      ),
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      flex: 1,
      renderCell: (params) => (
        // <div className="flex-section">
        <Grid container justifyContent='center'>
          <Button
            variant="contained"
            color="primary"
            size="small"
            href={params.getValue('actions')}
            target='_blank'
          >
            Descargar
          </Button>
        </Grid>
        // </div>
      ),
    },

  ];

  const docs = [
    { file: doc16, date: '2024-02-12' },
    { file: doc15, date: '2024-02-12' },
    { file: doc14, date: '2024-02-12' },
    { file: doc13, date: '2024-02-12' },
    { file: doc12, date: '2024-02-12' },
    { file: doc11, date: '2024-02-12' },
    { file: doc10, date: '2024-02-12' },
    { file: doc9, date: '2024-02-12' },
    { file: doc8, date: '2024-02-12' },
    { file: doc7, date: '2024-02-12' },
    { file: doc6, date: '2024-02-12' },
    { file: doc5, date: '2024-02-12' },
    { file: doc4, date: '2024-02-12' },
    { file: doc3, date: '2024-02-12' },
    { file: doc2, date: '2024-02-12' },
    { file: doc1, date: '2024-02-12' },
  ];

  const getFileName = (str) => str.replace(/^.*[\\\/]/, '')

  const rows = docs.map((doc, idx) => ({ id: idx, name: getFileName(doc.file), date: new Date(doc.date), actions: doc.file }))


  return (
    <div style={{ height: 450, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        disableColumnMenu
        pageSize={6}
        maxRows={3}
        disableSelectionOnClick
      />
    </div>
  )
}

export default TableDocuments
