import { Button, Divider, Grid, withStyles } from "@material-ui/core"
import { appActions } from "commons/reducer"
import styles from "./styles"
import moment from "moment"
import React, { useEffect, useContext } from "react"
import { useHistory, useParams } from "react-router-dom"
import { composedComponent } from "utils/functions"
import BudgetAssistsTable from "./components/BudgetAssistsTable"
import BudgetLaborsTable from "./components/BudgetLaborsTable"
import BudgetSignatures from "./components/BudgetSignatures"
import BudgetSparePartsTable from "./components/BudgetSparePartsTable"
import BudgetSummary from "./components/BudgetSummary"
import BudgetWorkshopLaborsTable from "./components/BudgetWorkshopLaborsTable"
import { budgetDetailsActions } from "./reducer"
import saga from "./saga"

import SocketContext from "commons/context/socket"

const BudgetDetails = (props) => {
  const { actions, classes } = props
  const params = useParams()
  const id = Number(params.id)
  const history = useHistory()

  const socket = useContext(SocketContext);

  useEffect(() => {
    actions.getBudget({ id: id })
  }, [])

  const printInfo = () => {
    let printContents = document.getElementById("printablediv").innerHTML
    let originalContents = document.body.innerHTML
    document.body.innerHTML = printContents
    window.print()
    document.body.innerHTML = originalContents
    history.go(0)
  }

  const downloadExcel = () => actions.generateExcel({ id: id, socketId: socket });

  return (
    <>
      <div id="printablediv">
        <div className={classes.fontSize}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.dividerMargin}
          >
            <Grid item md={12} lg={12} xs={12}>
              Atención Señor(es)
            </Grid>
            <Grid item md={12} lg={12} xs={12}>
              DIRECCION DE LOGISTICA DE CARABINEROS
            </Grid>
            <Grid item md={12} lg={12} xs={12}>
              61938500-4
            </Grid>
            <Grid item md={12} lg={12} xs={12}>
              AV. LIBERTADOR BERNARDO O'HIGGINS
            </Grid>
            <Grid item md={12} lg={12} xs={12}>
              Región Metropolitana - CHILE
            </Grid>
            <Grid
              container
              item
              md={12}
              lg={12}
              xs={12}
              justifyContent="center"
              className={classes.titleBoldText}
            >
              Cotización:{" "}
              {props.controls?.budget?.cotizationNumber
                ? props.controls?.budget?.cotizationNumber
                : "--"}
            </Grid>

            <Grid container justifyContent="center" style={{ marginTop: "2rem" }}>
                <Grid item container xs={6} justifyContent="center">
                  <Grid item container xs={12} spacing={4} justifyContent="flex-start">
                    <Grid item container xs={6} justifyContent="flex-end"><span className={classes.boldText}>Marca:</span></Grid>
                    <Grid item container xs={6}><span>{props.controls?.budget?.vehicle_brand ? props.controls?.budget?.vehicle_brand : "--"}</span></Grid>
                  </Grid>
                  <Grid item container xs={12} spacing={4} justifyContent="flex-start">
                    <Grid item container xs={6} justifyContent="flex-end"><span className={classes.boldText}>Modelo:</span></Grid>
                    <Grid item><span>{props.controls?.budget?.service?.vehicle?.model ? props.controls?.budget?.service?.vehicle?.model : "--"}</span></Grid>
                  </Grid>
                  <Grid item container xs={12} spacing={4} justifyContent="flex-start">
                    <Grid item container xs={6} justifyContent="flex-end"><span className={classes.boldText}>Sigla Institucional:</span></Grid>
                    <Grid item><span>{props.controls?.budget?.service?.vehicle?.plateNumber ? props.controls?.budget?.service?.vehicle?.plateNumber : "--"}</span></Grid>
                  </Grid>
                  <Grid item container xs={12} spacing={4} justifyContent="flex-start">
                    <Grid item container xs={6} justifyContent="flex-end"><span className={classes.boldText}>VIN:</span></Grid>
                    <Grid item><span>{props.controls?.budget?.service?.vehicle?.vin ? props.controls?.budget?.service?.vehicle?.vin : "--"}</span></Grid>
                  </Grid>
                  <Grid item container xs={12} spacing={4} justifyContent="flex-start">
                    <Grid item container xs={6} justifyContent="flex-end"><span className={classes.boldText}>Kmts:</span></Grid>
                    <Grid item><span>{props.controls?.budget?.service?.mileage ? new Intl.NumberFormat("en-DE").format(props.controls?.budget?.service?.mileage) : "--"}</span></Grid>
                  </Grid>
                  <Grid item container xs={12} spacing={4} justifyContent="flex-start">
                    <Grid item container xs={6} justifyContent="flex-end"><span className={classes.boldText}>Horometro:</span></Grid>
                    <Grid item><span>-</span></Grid>
                  </Grid>
                  <Grid item container xs={12} spacing={4} justifyContent="flex-start">
                    <Grid item container xs={6} justifyContent="flex-end"><span className={classes.boldText}>DOE:</span></Grid>
                    <Grid item><span>{props.controls?.budget?.service?.doe ? props.controls?.budget?.service?.doe : "--"}</span></Grid>
                  </Grid>
                </Grid>
                <Grid item container xs={6} justifyContent="center">
                  <Grid item container xs={12} spacing={4} justifyContent="flex-start">
                    <Grid item container xs={6} justifyContent="flex-end"><span className={classes.boldText}>Agendador:</span></Grid>
                    <Grid item><span>{props.controls?.budget?.scheduler_name ? props.controls?.budget?.scheduler_name : "--"}</span></Grid>
                  </Grid>
                  <Grid item container xs={12} spacing={4} justifyContent="flex-start">
                    <Grid item container xs={6} justifyContent="flex-end"><span className={classes.boldText}>Fecha Agendamiento:</span></Grid>
                    <Grid item><span>{props.controls?.budget?.service?.date ? moment(props.controls?.budget?.service?.date).format("DD-MM-YYYY") : "--"}</span></Grid>
                  </Grid>
                  <Grid item container xs={12} spacing={4} justifyContent="flex-start">
                    <Grid item container xs={6} justifyContent="flex-end"><span className={classes.boldText}>Fecha Ingreso:</span></Grid>
                    <Grid item><span>{props.controls?.budget?.date_added ? moment(props.controls?.budget?.date_added).format("DD-MM-YYYY") : "--"}</span></Grid>
                  </Grid>
                  <Grid item container xs={12} spacing={4} justifyContent="flex-start">
                    <Grid item container xs={6} justifyContent="flex-end"><span className={classes.boldText}>Fecha Cotización:</span></Grid>
                    <Grid item><span>{props.controls?.budget?.date ? moment(props.controls?.budget?.date).format("DD-MM-YYYY") : "--"}</span></Grid>
                  </Grid>
                  <Grid item container xs={12} spacing={4} justifyContent="flex-start">
                    <Grid item container xs={6} justifyContent="flex-end"><span className={classes.boldText}>Orden:</span></Grid>
                    <Grid item><span>{props.controls?.budget?.order_number ? props.controls?.budget?.order_number : "--"}</span></Grid>
                  </Grid>
                  <Grid item container xs={12} spacing={4} justifyContent="flex-start">
                    <Grid item container xs={6} justifyContent="flex-end"><span className={classes.boldText}>Días hábiles reparación:</span></Grid>
                    <Grid item><span>{props.controls?.budget?.repairment_days ? props.controls?.budget?.repairment_days : "--"}</span></Grid>
                  </Grid>
                </Grid>
            </Grid>

            {/* <Grid
              container
              item
              md={3}
              lg={3}
              xs={3}
              justifyContent="flex-end"
              className={classes.boldText}
            >
              DOE:
            </Grid>
            <Grid
              container
              item
              md={3}
              lg={3}
              xs={3}
              justifyContent="start"
              className={classes.topInfoText}
            >
              {props.controls?.budget?.service.doe ?? "--"}
            </Grid> */}
          </Grid>
          <Divider className={classes.dividerMargin} />
          <Grid container justifyContent="center" alignItems="center">
            <Grid item md={12} lg={12} xs={12} className={classes.boldText}>
              Servicios Solicitados:
            </Grid>
            <Grid container item md={12} lg={12} xs={12} justifyContent="center">
              {props.controls?.budget?.service?.description}
            </Grid>
          </Grid>
          <BudgetLaborsTable
            budgetLabors={props.controls.budget.budget_labor}
            className={{
              containerTopMargin: classes.containerTopMargin,
              bottomRight: classes.bottomRight,
              outerColumn: classes.outerColumn,
              centerColumn: classes.centerColumn,
              borderCell: classes.borderCell,
              tableHeader: classes.tableHeader,
              alignedRight: classes.alignedRight,
              alignedLeft: classes.alignedLeft,
              leftCell: classes.leftCell,
              rightCell: classes.rightCell,
            }}
          ></BudgetLaborsTable>
          {/* <BudgetAssistsTable
            budgetInfo={props.controls.budget}
            className={{
              containerTopMargin: classes.containerTopMargin,
              bottomRight: classes.bottomRight,
              outerColumn: classes.outerColumn,
              centerColumn: classes.centerColumn,
              borderCell: classes.borderCell,
              tableHeader: classes.tableHeader,
              alignedRight: classes.alignedRight,
              alignedLeft: classes.alignedLeft,
              leftCell: classes.leftCell,
              rightCell: classes.rightCell,
            }}
          ></BudgetAssistsTable> */}
          <BudgetWorkshopLaborsTable
            budgetLabors={props.controls.budget.budget_labor}
            className={{
              containerTopMargin: classes.containerTopMargin,
              bottomRight: classes.bottomRight,
              outerColumn: classes.outerColumn,
              centerColumn: classes.centerColumn,
              borderCell: classes.borderCell,
              tableHeader: classes.tableHeader,
              alignedRight: classes.alignedRight,
              alignedLeft: classes.alignedLeft,
              leftCell: classes.leftCell,
              rightCell: classes.rightCell,
            }}
          ></BudgetWorkshopLaborsTable>
          <BudgetSparePartsTable
            budgetSpareParts={props.controls.budget.budget_spare_parts}
            className={{
              containerTopMargin: classes.containerTopMargin,
              bottomRight: classes.bottomRight,
              outerColumn: classes.outerColumn,
              centerColumn: classes.centerColumn,
              borderCell: classes.borderCell,
              tableHeader: classes.tableHeader,
              alignedRight: classes.alignedRight,
              alignedLeft: classes.alignedLeft,
              leftCell: classes.leftCell,
              rightCell: classes.rightCell,
            }}
          ></BudgetSparePartsTable>
          <BudgetSummary
            total={props.controls.budget.total}
            vat={props.controls.budget.vat}
            net_value={props.controls.budget.net_value}
            className={{
              containerTopMargin: classes.containerTopMargin,
              bottomRight: classes.bottomRight,
              outerColumn: classes.outerColumn,
              centerColumn: classes.centerColumn,
              borderCell: classes.borderCell,
              tableHeader: classes.tableHeader,
              alignedRight: classes.alignedRight,
              alignedLeft: classes.alignedLeft,
              alignedRightBold: classes.alignedRightBold,
            }}
          ></BudgetSummary>
          <BudgetSignatures
            className={{
              containerTopMargin: classes.containerTopMargin,
              fontSize: classes.fontSize,
              signatureStyle: classes.signatureStyle,
            }}
          ></BudgetSignatures>
        </div>
      </div>
      <Grid sm={12} xs={12} container spacing={2} alignItems="center" justifyContent="center">
        <Grid sm={12} xs={12} container spacing={2} alignItems="center" justifyContent="center">
          <Grid item>
            <Button variant="contained" className={classes.buttonContained} onClick={printInfo}>
              IMPRIMIR
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" className={classes.buttonContained} onClick={downloadExcel}>
              DESCARGAR EXCEL
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default composedComponent(BudgetDetails, saga, {
  saga: "budgetDetails",
  states: ["budgetDetails.controls"],
  middlewares: [withStyles(styles)],
  actions: [budgetDetailsActions, appActions],
})
